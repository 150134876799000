import React from 'react';
import { Link } from 'react-router-dom';
import './OceanScreen.css';
import Page from '../Page/Page';

function OceanScreen() {

  const rectangles = [];

  for (let i = 0; i < 24; i++) {
    rectangles.push(<Link className="OceanRectangle" to="links-list" />)
  }

  return (
    <Page theme="default">
      <div className="Screen OceanScreen">
        <div className="Heading1">Contact</div>
        <div className="OceanGraphicsWrapper">
          <div className="OceanGraphicsInner">
            {rectangles.map(rectangle => rectangle)}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default OceanScreen;
