import React from 'react';
import { Link } from 'react-router-dom';
import './MultiBallScreen.css';
import Navigation from '../Navigation/Navigation';
import Page from '../Page/Page';

const ellipses = [30, 27, 22, 17, 26, 21, 16, 45, 40, 35, 25, 20, 15, 44, 39, 34, 29, 43, 38, 33, 24, 19, 14, 49, 48, 47, 46, 42, 37, 32, 28, 41, 36, 31, 23, 18, 13];

function MultiBallScreen() {
  return (
    <Page theme="default" theme="pink">
      <div className="Screen Screen2">
        <div className="GraphicsBlock DotFieldGraphicsBlock">
          <div className="GraphicsWrapper">
            <Link className="Rectangle Rectangle15" to="/customers" />
            <Link className="Rectangle Rectangle16" to="/customers" />
            <Link className="Rectangle Rectangle17" to="/customers" />
            <Link className="Rectangle Rectangle18" to="/customers" />
            <Link className="Rectangle Rectangle19" to="/customers" />
            <Link className="Rectangle Rectangle20" to="/customers" />

            {ellipses.map(key => <Link className={`Ellipse Ellipse${key}`} to="/customers" />)}
          </div>
        </div>
        <div className="ContentBlock">
          <p className="Text">A one woman IT company.</p>
          <p className="Text">A one woman frontend architect with a great mind for directing brilliant teams. Working in
            the business before there was even a frontend, developing fantastic systems for fantastic people with "happy
            coding" as a proof of concept.</p>
        </div>
      </div>
    </Page>
  );
}

export default MultiBallScreen;

