import React from 'react';
import { Link } from 'react-router-dom';
import './SausageScreen.css';
import Page from '../Page/Page';

function SausageScreen() {

  const rectangles = [];

  for (let i = 0; i < 29; i++) {
    rectangles.push(<Link to="/customers-list" className="SausageRectangle" style={{ marginTop: `${4 * i}vmin` }} />)
  }

  return (
    <Page theme="default">
      <div className="Screen SausageScreen">
        <div className="Heading1">Customers</div>
        <div className="SausageGraphicsWrapper">
          {rectangles.map(rectangle => rectangle)}
        </div>
      </div>
    </Page>
  );
}

export default SausageScreen;
