import React, { useState } from 'react';
import './Page.css';
import Navigation from '../Navigation/Navigation';
import Burger from '../Burger/Burger';

const Page = ({ theme, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Page">
      <div className={`ScreenWrapper ${isOpen ? 'ScreenWrapperPushLeft' : ''}`}>
        {children}
        {/*<Burger onClick={() => setIsOpen(!isOpen)} theme={theme} />*/}
      </div>
      {/*<Navigation isOpen={isOpen} />*/}
    </div>
  );
};

export default Page;
