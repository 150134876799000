import React from 'react';
import { Link } from 'react-router-dom';
import './InitialsScreen.css';
import Page from '../Page/Page';

function InitialsScreen() {
  return (
    <Page theme="purple-blue">
      <div className="Screen InitialsScreen">
        <Link className="InitialsWrapper" to="/"><span className="Initials">C</span><span className="Initials">E</span></Link>
      </div>
    </Page>
  );
}

export default InitialsScreen;
