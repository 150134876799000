import React from 'react';
import { Link } from 'react-router-dom';
import './WaveScreen.css';
import Page from '../Page/Page';


function WaveScreen() {
  return (
    <Page theme="red">
      <div className="Screen WaveScreen">
        <div className="GraphicsBlock WaveGraphicsBlock">
          <div className="WaveGraphicsWrapper">
            <div className="WaveRectangleWrapper">
              <div className="WaveRectangleInner">
                <button className="WaveRectangle WaveRectangle1" />
                <button className="WaveRectangle WaveRectangle2" />
              </div>
            </div>

            <div className="WaveEllipseWrapper">
              <Link className="WaveEllipse WaveEllipse1" to="/links" />
              <Link className="WaveEllipse WaveEllipse2" to="/links" />
              <Link className="WaveEllipse WaveEllipse3" to="/links" />
              <Link className="WaveEllipse WaveEllipse4" to="/links" />
              <Link className="WaveEllipse WaveEllipse5" to="/links" />
            </div>
          </div>
        </div>
        <div className="ContentBlock">
          <ul className="LinkList WaveLinkList">
            <li><a href="https://hoy.se/" className="Link">HOY</a></li>
            <li><a href="http://libero.com" className="Link">Libero</a></li>
            <li><a href="https://www.tenanet.co.uk" className="Link">TENA Net</a></li>
            <li><a href="https://www.incipientus.com/" className="Link">Incipientus</a></li>
            <li><a href="http://tenabutiken.se" className="Link">TENA Webshop</a></li>
            <li><a href="https://www.thecompadres.se/" className="Link">The Compadres</a></li>
            <li><a href="https://www.scr.se/" className="Link">SCR Svensk Camping</a></li>
          </ul>
        </div>
      </div>
    </Page>
  );
}

export default WaveScreen;
