import React from 'react';
import { Link } from 'react-router-dom';
import './ForestScreen.css';
import Page from '../Page/Page';

const ellipses = [];

for (let i = 0; i < 12; i++) {
  ellipses.push(<Link className="Tree" to="/about" />)
}

function ForestScreen() {
  return (
    <Page theme="default">
      <div className="ForestScreen Screen">
        <div className="Heading1">Celebrating Excellence</div>
        <div className="ForestScreenGraphicsWrapper">
          {ellipses.map(ellipse => ellipse)}
        </div>
      </div>
    </Page>
  );
}

export default ForestScreen;
