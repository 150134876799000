import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import './App.css';
import ForestScreen from './components/ForestScreen/ForestScreen';
import MultiBallScreen from './components/MultiBallScreen/MultiBallScreen';
import SausageScreen from './components/SausageScreen/SausageScreen';
import WaveScreen from './components/WaveScreen/WaveScreen';
import OceanScreen from './components/OceanScreen/OceanScreen';
import FlagScreen from './components/FlagScreen/FlagScreen';
import InitialsScreen from './components/InitialsScreen/InitialsScreen';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about" component={MultiBallScreen} />
        <Route path="/customers" component={SausageScreen} />
        <Route path="/customers-list" component={WaveScreen} />
        <Route path="/links" component={OceanScreen} />
        <Route path="/links-list" component={FlagScreen} />
        <Route path="/end" component={InitialsScreen} />
        <Route path="/" component={ForestScreen} />
      </Switch>
    </Router>
  );
}

export default App;
