import React from 'react';
import { Link } from 'react-router-dom';
import './FlagScreen.css';
import Page from '../Page/Page';


function Flag() {
  return (
    <Page theme="blue">
      <div className="Screen FlagScreen">
        <div className="GraphicsBlock FlagGraphicsBlock">
          <div className="FlagGraphicsWrapper">
            <div className="FlagRectangleWrapper">
              <div className="FlagRectangleInner">
                <Link className="FlagRectangle FlagRectangle1" to="/end" />
                <Link className="FlagRectangle FlagRectangle2" to="/end" />
              </div>
            </div>
          </div>
        </div>
        <div className="ContentBlock">
          <ul className="LinkList FlagLinkList">
            <li><a href="mailto:therese@teriksson.se" className="Link">Mail</a></li>
            <li><a href="https://www.linkedin.com/in/therese-eriksson-87539426/" className="Link">Linkedin</a></li>{/*
            <li><a href="https://www.mixcloud.com/teriksson/" className="Link">Mixcloud</a></li>
            <li><a href="https://www.instagram.com/celebrating.excellence/" className="Link">Instagram</a></li>
            <li><a href="https://github.com/teriksson" className="Link">Github</a></li>*/}
            <li><a href="https://goo.gl/maps/AkysKpRfp6AE3ZST9" className="Link">Office</a></li>
          </ul>
        </div>
      </div>
    </Page>
  );
}

export default Flag;
